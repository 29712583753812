const tariffSelector = document.querySelector('.tariff-selector')
const mainForm = document.querySelector('.main-form')
const tariffButtons = document.querySelectorAll('.tariffButton')
const formHidden = document.querySelectorAll('.form-row__hidden')

export function selectTariff() {
    // on click on tariff button
    tariffButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            e.preventDefault()

            // get tarif id from data-id attribute
            const tariffId = button.dataset.id

            // select option with value tariff id to tariffSelector
            const tariffSelectorOption = tariffSelector.querySelector(`option[value="${tariffId}"]`)

            tariffSelectorOption.selected = true

            // show hidden form rows
            formHidden.forEach(row => {
                row.classList.remove('form-row__hidden')
            })

            // scroll to form
            mainForm.scrollIntoView({ behavior: 'smooth' })
        })
    })
}

