import './../../scss/blocks/_tariff-block.scss'

import { selectTariff } from './../components/tariffSelector.js'
import tariffs from './../components/tariffs.js'
import { tooltipFunc } from './../components/tooltip.js'
import { scrollTariff } from './../components/tariffScroller.js'

document.addEventListener(
    'DOMContentLoaded',
    () => {
        selectTariff()
        tariffs()
        tooltipFunc()
        scrollTariff()
    },
)
