const tooltips = document.querySelectorAll('.tooltip')
const tooltipText = document.querySelectorAll('.tooltip-container')
const closeBtn = document.querySelector('.close-btn')

export function tooltipFunc() {

    tooltips.forEach(item => {
        item.addEventListener('click', (e) => {
            e.preventDefault()
            item.classList.add('active')
            item.parentElement.parentElement.previousElementSibling.previousElementSibling.classList.add('active')
        })

        item.addEventListener('mouseover', (e) => {
            e.preventDefault()
            item.classList.add('active')
            item.parentElement.parentElement.previousElementSibling.previousElementSibling.classList.add('active')
        })

        item.addEventListener('mouseout', (e) => {
            e.preventDefault()
            item.classList.remove('active')
            item.parentElement.parentElement.previousElementSibling.previousElementSibling.classList.remove('active')
        })
    })

    closeBtn.addEventListener('click', (e) => {
        e.preventDefault()
        tooltipText.classList.remove('active')
    })
}
