const tariffs = () => {
    const btns = document.querySelectorAll('.tariff .switch-theme__btn')
    const contents = document.querySelectorAll('.tariff .tariff-content')
    const buttonsBackground = document.querySelector('.tariff .switch-theme .background')

    const setButtonsBackgroundPosition = (element) => {
        const width = element.offsetWidth
        buttonsBackground.style.width = `${width}px`

        const left = element.offsetLeft
        buttonsBackground.style.left = `${left}px`
    }

    btns.forEach((el, i) => {
        el.addEventListener('click', () => {
            btns.forEach((btn) => {
                btn.classList.remove('switch-theme__btn--active')
            })
            contents.forEach((content) => {
                content.classList.remove('tariff-content--active')
            })
            el.classList.add('switch-theme__btn--active')
            contents[i]?.classList.add('tariff-content--active')

            setButtonsBackgroundPosition(el)
        })
    })

    const activeButton = document.querySelector('.tariff .switch-theme__btn--active')
    setButtonsBackgroundPosition(activeButton)

    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const activeButtonInView = document.querySelector('.tariff .switch-theme__btn--active')
                setButtonsBackgroundPosition(activeButtonInView)
            }
        })
    }, {
        root: null,
        threshold: 0.1,
    })

    // Observe the tariff element
    const tariffElement = document.querySelector('.tariff')
    observer.observe(tariffElement)
}

export default tariffs
